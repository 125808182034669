import React, { Component } from "react";
import how from '../images/howtobuy/how.png'
import how1 from '../images/howtobuy/how1.png'
import how2 from '../images/howtobuy/how2.png'
import how3 from '../images/howtobuy/how3.png'
import how4 from '../images/howtobuy/how4.png'
import how5 from '../images/howtobuy/how5.png'
import how6 from '../images/howtobuy/how6.png'
import how7 from '../images/howtobuy/how7.png'
import how8 from '../images/howtobuy/how8.png'
import how9 from '../images/howtobuy/how9.png'
import how10 from '../images/howtobuy/how10.png'
import how11 from '../images/howtobuy/how11.png'
import myVideo from '../images/howtobuy/teafinance.mp4'
import ReactPlayer from 'react-player'
import { InView } from 'react-intersection-observer';
import { motion } from "framer-motion"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"




// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);

class Howtobuy extends Component {
    constructor() {
        super();
         this.state = {showVideo: false, showSlide: true};
    
      }

    render() {
        
        
        return (
            <>
            <div className="howtobuy">
                <div>
                <div className="howtobuy-header">
                <InView threshold={1} triggerOnce={true} >
                {({ ref, ref2, inView }) => (
                    <>
                    <motion.h2 ref={ref} transition={{ duration: 0.5, delay: 0.2 }}  animate={inView ? { opacity: 1, y: 0 } : {opacity: 0, y: 20}}>How to buy</motion.h2>
                    <motion.h1 ref={ref2} transition={{ duration: 0.5, delay: 0.2 }}  animate={inView ? { opacity: 1, y: 0 } : {opacity: 0, y: 20}}>TEAFI TOKEN</motion.h1>
                    </>
                )}
                </InView>
                    <hr></hr>
              
                </div>


               
                        <div className="slide-container">
                          <Swiper
                            spaceBetween={0}
                            slidesPerView={2}
                            pagination={true}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetweenSlides: 30
                                },
                                
                                769: {
                                    slidesPerView: 2,
                                    spaceBetweenSlides: 40
                                }
                            }}
                          >
                        <SwiperSlide className='player-wrapper' ><ReactPlayer autoPlay muted className='react-player' playing={true} controls={true} width='100%' height='100%' url={myVideo} /></SwiperSlide>
                        <SwiperSlide><a><img src={how} alt="how" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how1} alt="how1" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how2} alt="how2" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how3} alt="how3" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how4} alt="how4" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how5} alt="how5" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how6} alt="how6" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how7} alt="how7" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how8} alt="how8" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how9} alt="how9" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how10} alt="how10" /></a></SwiperSlide>
                        <SwiperSlide><a><img src={how11} alt="how11" /></a></SwiperSlide>
    </Swiper>
                        </div>
             
                
                </div>
            </div>
            </>
        );
    }
}

export default Howtobuy;
