import React, { Component } from "react";
import blank from "../images/blank.jpg";
import cardmockup from "../images/cardmockup.png";
import { motion } from "framer-motion";
import logosecondary from "../images/logo-secondary.png";
import { InView } from "react-intersection-observer";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter, faFacebook, faTelegram} from "@fortawesome/free-brands-svg-icons"

class Community extends Component {
  constructor() {
    super();
    // this.state = {var: null};
    // this.element = this.element.bind(this);
  }

  // element() {
  //  this.setState({
  //   var: true,
  //  });
  // }

  render() {
    return (
      <>
        <div className="community">
          <div>
            <div className="community-header">
              <InView threshold={1} triggerOnce={true}>
                {({ ref, ref2, inView }) => (
                  <>
                    <motion.h2
                      ref={ref}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                      }
                    >
                      Community
                    </motion.h2>
                    <motion.h1
                      ref={ref2}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                      }
                    >
                      Join the Crypto Revolution
                    </motion.h1>
                  </>
                )}
              </InView>

              <hr></hr>
              <p>
                The TEAFI community is a globally distributed home to token
                holders, validators, and members supporting the protocol.
              </p>
            </div>

          </div>

          
        </div>

        <div className="community-var">
              <div>
                <div className="twitter-hud">
                <span className="social-icon" ><FontAwesomeIcon icon={faTwitter}/></span>
                <small>announcements</small>
                <a href="https://twitter.com/TeafiCrypto" target="_blank">
                        <motion.button whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }} className="community-button">
                            <p>Twitter</p>
                        </motion.button>
                    </a>
                </div>
                <div className="facebook-hud">
                <span className="social-icon" ><FontAwesomeIcon icon={faFacebook}/></span>
                <small>announcements</small>
                <a href="https://facebook.com/Teafinance" target="_blank">
                        <motion.button whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }} className="community-button">
                            <p>facebook</p>
                        </motion.button>
                    </a>
                </div>
                <div className="instagram-hud">
                <span className="social-icon" ><FontAwesomeIcon icon={faInstagram}/></span>
                <small>announcements</small>
                <a href="https://www.instagram.com/teafinance/" target="_blank">
                        <motion.button whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }} className="community-button">
                            <p>instagram</p>
                        </motion.button>
                    </a>
                </div>
                <div className="telegram-hud">
                <span className="social-icon" ><FontAwesomeIcon icon={faTelegram}/></span>
                <small>announcements</small>
                <a href="https://t.me/Teafinance" target="_blank">
                        <motion.button whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }} className="community-button">
                            <p>telegram</p>
                        </motion.button>
                    </a>
                </div>
                
              </div>

              
            </div>
            <div className="form-wrapper">
                <p>
                  Keep up with Daily $TeaFi news and weekly crypto tea
                </p>
                <form
                  name="TEAFI-NEWSLETTER"
                  netlify="true"
                  method="post"
                  netlify-honeypot="bot-field"
                >
                
                  <input
                    type="hidden"
                    name="form-name"
                    value="TEAFI-NEWSLETTER"
                    
                  />
                  <input type="email" name="email" placeholder="Your Email" required />
                  <button type="submit"><svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.7071 8.7071C30.0976 8.31658 30.0976 7.68341 29.7071 7.29289L23.3431 0.92893C22.9526 0.538406 22.3195 0.538406 21.9289 0.92893C21.5384 1.31945 21.5384 1.95262 21.9289 2.34314L27.5858 8L21.9289 13.6569C21.5384 14.0474 21.5384 14.6805 21.9289 15.0711C22.3195 15.4616 22.9526 15.4616 23.3431 15.0711L29.7071 8.7071ZM8.74228e-08 9L29 9L29 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#1A1627"/>
</svg>
</button>
                </form>
                </div>
            
      </>
    );
  }
}

export default Community;
