import React from "react";
import "./_scss/theme";
import { useEffect } from "react";
import { useRef } from "react";



import Header2 from "./components/header2";
import Header from "./components/header";
import About from "./components/about";
import Tokenomics from "./components/tokenomics"
import Roadmap from "./components/roadmap"
import Partners from "./components/partners"
import Community from "./components/community"
import Future from "./components/future"
import Faqapp from "./components/faq"
import Background from "./components/background";
import Footer from "./components/footer"
import Howtobuy from "./components/howtobuy";
import {TweenLite, TweenMax, Linear, Sine, Power3} from 'gsap';

import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactDOM from 'react-dom';

const IndexPage = () => {
    
    return (
        <BrowserRouter>
            <Main /> 
        </BrowserRouter>
       
    );
};

const Home = () => {
    const ref = useRef();
    
  useEffect(() => {
    var falling = true;

    TweenLite.set("#leaf-container",{perspective: 3000})
    TweenLite.set("img",{xPercent:"-50%",yPercent:"-50%"})
    var i
    var total = 20;

    var body = document.body,
        html = document.documentElement;

    var height = Math.max( body.offsetHeight, html.clientHeight, html.offsetHeight );

    
    var container = document.getElementById("leaf-container"),	w = window.innerWidth , h = height;
    container.style.height = height + "px"

     for (i=0; i<total; i++){ 
       var Div = document.createElement('div');
       TweenLite.set(Div,{attr:{class:'dot'},x:R(0,w),y:R(-200,-150),z:R(100,250)});
       container.appendChild(Div);
       animm(Div);
     }
     
     function animm(elm){   
       TweenMax.to(elm,R(200,115),{y:h,ease:Linear.easeNone,repeat: -1,delay: -221});
       TweenMax.to(elm,R(8,16),{x: '+=100',rotationZ:R(0,180),repeat:-1,yoyo:true,ease:Sine.easeInOut});
       TweenMax.to(elm,R(2,8),{rotationX:R(0,360),rotationY:R(0,360),repeat:-1,yoyo:true,ease:Sine.easeInOut,delay:-5});
      };
    
      function R(min,max) {return min+Math.random()*(max-min)};

      window.onresize = function() {
        var body = document.body,
        html = document.documentElement;

        var height = Math.max(  body.offsetHeight, html.clientHeight, html.offsetHeight );

        container.style.height = height + "px"

       var w = window.innerWidth , h = height


        function animm(elm){   
            TweenMax.to(elm,R(80,105),{y:h,ease:Linear.easeNone,repeat: -1,delay: -221});
            TweenMax.to(elm,R(8,16),{x: '+=100',rotationZ:R(0,180),repeat:-1,yoyo:true,ease:Sine.easeInOut});
            TweenMax.to(elm,R(2,8),{rotationX:R(0,360),rotationY:R(0,360),repeat:-1,yoyo:true,ease:Sine.easeInOut,delay:-5});
           };
      }
    }, []);

  return (
   <>
  
    <div id="leaf-container"></div>
    <div role="section 1" className="section1">
    
      <Header /> 
      <Header2 />
    </div>

    <div role="section 2" className="section2">
      <About />
    </div>

    <div id="tokenomics" role="section 3" className="section3">
      <Tokenomics />
    </div>

    <div id="roadmap" role="section 4" className="section4">
      <Roadmap />
    </div>

    <div id="howtobuy" role="section 5" className="section5">
      <Howtobuy />
    </div>
    
    <div role="section 6" className="section6">
      <Future />
    </div>
    
    <div id="community" role="section 7" className="section7">
      <Community />
    </div>

    <div id="faq" role="section 8" className="section8">
      <Faqapp />
    </div>

    

    <div role="section 9" className="section9">
        <Footer />
    </div>

    

</>
  )
 
};

const Main = () => (
    <Switch>
        <Route exact path="/" component={Home}></Route>
    </Switch>
);

ReactDOM.render(<IndexPage />, document.getElementById("root"));














