import React, { Component } from "react";
import blank from '../images/blank.jpg'
import cardmockup from '../images/cardmockup.png'
import { motion } from "framer-motion"
import logosecondary from '../images/logo-secondary.png'
import { InView } from 'react-intersection-observer';
import {Link} from 'react-scroll'

class About extends Component {
    constructor() {
        super();
        // this.state = {var: null};
        // this.element = this.element.bind(this);
      }

      // element() {
      //  this.setState({
      //   var: true,  
      //  });
      // }

      

    render() {

        return (
            <>
            <div className="about">
                <div>
                <div className="about-var">
                <InView threshold={1} triggerOnce={true} >
                {({ ref, ref2, inView }) => (
                    <>
                    <motion.h2 ref={ref} transition={{ duration: 0.5, delay: 0.2 }}  animate={inView ? { opacity: 1, y: 0 } : {opacity: 0, y: 20}}>EARN with</motion.h2>
                    <motion.h1 ref={ref2} transition={{ duration: 0.5, delay: 0.2 }}  animate={inView ? { opacity: 1, y: 0 } : {opacity: 0, y: 20}}>TEAFI TOKEN</motion.h1>
                    </>
                )}
                </InView>
                
                <hr></hr> 
  
                <p>Put your crypto to work.</p>
                <p>A decentralized token with each $TeaFi holder receiving 2.5% of all transactions back to your wallets. (Auto-Farming).</p>
                <p>Watch your $TeaFi wallets climb. Holders have complete control over their digital assets without needing to involve an intermediary.</p>
                <p>As our $TeaFi community builds, so will TeaFinance roots. With our current transaction fees assisting in the blossoming of our Tea leaves, we want everyone to take a sip.</p>
                
                <Link activeClass="active" to="future" spy={true} smooth={true}>
                        <motion.button whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }} className="future-button">
                            <p>See the future</p>
                        </motion.button>
                    </Link>
                </div>
               
             
                </div>
                
            </div>
            </>
        );
    }
}

export default About;
