import React, { Component } from "react";
import iphone from "../images/iphone.png";
import iphone2 from "../images/iphone2.png";
import card from "../images/cardmockup.png";
import { InView } from "react-intersection-observer";
import { motion } from "framer-motion";

class Future extends Component {
  constructor() {
    super();
    // this.state = {var: null};
    // this.element = this.element.bind(this);
  }

  // element() {
  //  this.setState({
  //   var: true,
  //  });
  // }

  render() {
    return (
      <>
        <div className="future">
          <div>
            

            <div className="future-var">
              <InView threshold={1} triggerOnce={true}>
                {({ ref, ref2, inView }) => (
                  <>
                    <motion.h2
                      ref={ref}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                      }
                    >
                      Tea
                    </motion.h2>
                    <motion.h1
                      ref={ref2}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                      }
                    >
                      In the future
                    </motion.h1>
                  </>
                )}
              </InView>

              <hr></hr>
              <ul>
                <li>Ability to send $TeaFi to your phone wallet.</li>
                <li>
                  Send $Teafi to friends & family including businesses and
                  internationally, easily just from your app
                </li>
                <li>Track your $Teafi rewards & spending</li>
                <li>Access to your $TeaFinance debit card</li>
              </ul>

          
              
            </div>

            <div className="phone-img2">
              <img src={iphone} alt="iphone img" className="blank" />
            </div>

            
          </div>
        </div>

        <div className="future2">
          <div>
            <div className="future-var2">
              <p>
                Looking at our tea leaves and they were in the shape of a
                prepaid debit card. Weird. Not ones to ignore a sign, we are now
                in the process of building a personal prepaid debit card with
                rewards and perks from all your favorite brands. All you’ll have
                to do is fill your $TeaFi card up with $TeaFi tokens through
                your app and watch your wallet increase as you spend.
              </p>

            
            </div>

            <div className="card-img2">
              <img src={card} alt="iphone img" className="blank" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Future;
