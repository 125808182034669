import React, { Component } from "react";
import blank from "../images/blank.jpg";
import cardmockup from "../images/cardmockup.png";
import { InView } from "react-intersection-observer";
import { motion } from "framer-motion";

class Roadmap extends Component {
  constructor() {
    super();
    // this.state = {var: null};
    // this.element = this.element.bind(this);
  }

  // element() {
  //  this.setState({
  //   var: true,
  //  });
  // }

  render() {
    return (
      <>
        <div className="roadmap">
          <div>
            <div className="roadmap-header">
              <InView threshold={1} triggerOnce={true}>
                {({ ref, ref2, inView }) => (
                  <>
                    <motion.h2
                      ref={ref}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                      }
                    >
                      Teafi token
                    </motion.h2>
                    <motion.h1
                      ref={ref2}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                      }
                    >
                      Roadmap
                    </motion.h1>
                  </>
                )}
              </InView>
              <hr></hr>
            </div>

            <div className="timeline-content-desktop">
              <div className="timeline-data">
                <div className="timeline-var">
                  <ul>
                    <li>Liquidity Locked</li>
                    <li>Launch Uniswap listing</li>
                    <li>Security Audit on Contract</li>
                    <li>Coin Market Cap</li>
                    <li>Coin Gecko Listing</li>
                    <li>$TeaFI community growth campaign</li>
                    <li>$TeaFI Marketing campaign</li>
                  </ul>
                </div>

                <div>
                  <span className="timeline-rounder"></span>
                  <span className="timeline-line"></span>
                </div>

                <div>
                  <p>Q1-Q2 2021</p>
                </div>
              </div>

              <div className="timeline-data">
                <div style={{ textAlign: "right" }}>
                  <p>Q1 2022</p>
                </div>

                <div>
                  <span className="timeline-rounder"></span>
                  <span className="timeline-line"></span>
                </div>

                <div className="timeline-var">
                  <ul>
                    <li>$Teafi IOS Application Launch</li>
                    <li>$TeaFI prepaid debit card launch</li>
                    <li>$Teafi Pay</li>
                  </ul>
                </div>
              </div>

              <div className="timeline-data">
                <div className="timeline-var">
                  <ul>
                    <li>Ability to send $TeaFi to your phone wallet.</li>
                    <li>
                      Send $Teafi to friends & family including businesses and
                      internationally, easily just from your app
                    </li>
                    <li>Track your $Teafi rewards & spending</li>
                    <li>Access to your $TeaFinance debit card</li>
                  </ul>
                </div>

                <div>
                  <span className="timeline-rounder"></span>
                  <span className="timeline-line"></span>
                </div>
                <div>
                  <p>TEA IN THE FUTURE</p>
                </div>
              </div>
            </div>

            <div className="timeline-content-mobile">
              <div className="timeline-data">
                <div>
                  <span className="timeline-rounder"></span>
                  <span className="timeline-line"></span>
                </div>

                <div className="timeline-var">
                  <ul>
                    <li>Liquidity Locked</li>
                    <li>Launch Uniswap listing</li>
                    <li>Security Audit on Contract</li>
                    <li>Coin Market Cap</li>
                    <li>Coin Gecko Listing</li>
                    <li>$TeaFI community growth campaign</li>
                    <li>$TeaFI Marketing campaign</li>
                  </ul>

                  <p>Q1, Q2 - 2021</p>
                </div>
              </div>

              <div className="timeline-data">
                <div>
                  <span className="timeline-rounder"></span>
                  <span className="timeline-line"></span>
                </div>

                <div className="timeline-var">
                  <ul>
                    <li>$Teafi IOS Application Launch</li>
                    <li>$TeaFI prepaid debit card launch</li>
                    <li>$Teafi Pay</li>
                  </ul>

                  <p>Q1 - 2022</p>
                </div>
              </div>

              <div className="timeline-data">
                <div>
                  <span className="timeline-rounder"></span>
                  <span className="timeline-line"></span>
                </div>

                <div className="timeline-var">
                  <ul>
                    <li>Ability to send $TeaFi to your phone wallet.</li>
                    <li>
                      Send $Teafi to friends & family including businesses and
                      internationally, easily just from your app
                    </li>
                    <li>Track your $Teafi rewards & spending</li>
                    <li>Access to your $TeaFinance debit card</li>
                  </ul>

                  <p>TEA IN THE FUTURE</p>
                </div>
              </div>
            </div>
          </div>

          
        </div>
        <div className="form-wrapper">
            <p>Signup to be the first to know when the Tea Card will launch</p>
            <form
              name="TEAFI-NEWSLETTER"
              netlify="true"
              method="post"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="TEAFI-NEWSLETTER" />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                required
              />
              <button type="submit">
                <svg
                  width="30"
                  height="16"
                  viewBox="0 0 30 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.7071 8.7071C30.0976 8.31658 30.0976 7.68341 29.7071 7.29289L23.3431 0.92893C22.9526 0.538406 22.3195 0.538406 21.9289 0.92893C21.5384 1.31945 21.5384 1.95262 21.9289 2.34314L27.5858 8L21.9289 13.6569C21.5384 14.0474 21.5384 14.6805 21.9289 15.0711C22.3195 15.4616 22.9526 15.4616 23.3431 15.0711L29.7071 8.7071ZM8.74228e-08 9L29 9L29 7L-8.74228e-08 7L8.74228e-08 9Z"
                    fill="#1A1627"
                  />
                </svg>
              </button>
            </form>
          </div>
      </>
    );
  }
}

export default Roadmap;
