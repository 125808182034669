import React, { Component } from "react";
import blank from '../images/blank.jpg'
import cardmockup from '../images/cardmockup.png'


class Partners extends Component {
    constructor() {
        super();
        // this.state = {var: null};
        // this.element = this.element.bind(this);
      }

      // element() {
      //  this.setState({
      //   var: true,  
      //  });
      // }

    render() {
        return (
            <>
            <div className="partners">
                <div>
                <div className="partners-header">
                    <h2>our</h2>
                    <h1>partners</h1>
                    <hr></hr>
                </div>
                
            <div className="partners-hud-section">
                <div>
                    <div className="hud"></div>
                    <div className="hud"></div>
                    <div className="hud"></div>
                </div>
            </div>

                </div>
            </div>
            </>
        );
    }
}

export default Partners;
