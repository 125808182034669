import React, { Component } from "react";
import { useEffect, useState } from "react";
import blank from "../images/blank.jpg";
import cardmockup from "../images/cardmockup.png";
import { InView } from "react-intersection-observer";
import { motion } from "framer-motion";
import callAPI from './utils'
const Web3 = require("web3")

const Tokenomics = () => {

  const web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/c89f801eef6146ba83703b9e497bdd25"))

  const [latestPrice, setLatestPrice] = useState(0);

	useEffect(() => {
		fetchData().then((chartData) => {
            web3.eth.getBalance("0xc25A0a8a327c7FAEfAcf558BdB0a8C378aE85bEa", function(err, result) {
                if (err) {
                  console.log(err)
                } else {
                  setLatestPrice(parseFloat(chartData.price[chartData.price.length - 1] * web3.utils.fromWei(result, "ether")).toFixed(2));
                
                }
              })
			
			
		});
	}, []);
    
    const fetchData = async () => {
        let data = { index: [], price: [], volumes: [] };
        let result = await callAPI("https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=1&interval=1m");
        for (const item of result.prices) {
            data.index.push(item[0]);
            data.price.push(item[1]);
        }
        for (const item of result.total_volumes) data.volumes.push(item[1]);
        return data;
      };



      
 
    return (
      <>
        <div className="tokenomics">
          <div>
            <div className="tokenomics-header">
              <InView threshold={1} triggerOnce={true}>
                {({ ref, ref2, inView }) => (
                  <>
                    <motion.h2
                      ref={ref}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                      }
                    >
                      
                    </motion.h2>
                    <motion.h1
                      ref={ref2}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                      }
                    >
                      What makes our tea hot?
                    </motion.h1>
                  </>
                )}
              </InView>

              <hr></hr>
              <p>
                Here are some other things that will make our TEA particularly worth sipping
              </p>
            </div>

            <div className="tokenomics-hud-section">
              <div>
                <div className="hud">
                  <a>
                    <p>TEAFI PAY</p>
                    <p>
                      A secured payment system to be able to accept crypto & spend crypto in all your nearest stores
                    </p>
                  </a>
                </div>
                <div className="hud">
                  <a>
                    <p>DEFI Wallet</p>
                    <p>
                      Store your $TeaFi <br />

                      Send your $TeaFi <br />

                      Earn just by holding $TeaFi

                    </p>
                  </a>
                </div>
                <div className="hud">
                  <a>
                    <p>Safe Auto-Farming</p>
                    <p>
                      $TeaFi tokens receive 2.5% of all transactions back to your
                      wallet
                    </p>
                  </a>
                </div>
                <div className="hud">
                  <a>
                    <p>TEAFI Marketing Wallet Value</p>
                    <p className="value">${latestPrice} usd</p>
                  </a>
                </div>
                <div className="hud">
                  <a>
                    <p>Prepaid Debit Card</p>
                    <p>
                      Upload your $TeaFi & Earn rewards <br />
                      Spend your $TeaFi at all stores accepting debit cards
                    </p>
                  </a>
                </div>
                <div className="hud">
                  <a>
                    <p>Floor Price</p>
                    <p>$0.0001</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }


export default Tokenomics;
