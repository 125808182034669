import React, { Component, useRef } from "react";
import { motion } from "framer-motion"
import headerlogo from '../images/logo.png'
import logosecondary from '../images/logo-secondary.png'
import Navdesktop from './nav-desktop'
import Navmobile from './nav-mobile'
import Cup from '../images/cup.png'





class Header2 extends Component {
    constructor() {
        super();
        // this.state = {var: null};
        // this.element = this.element.bind(this);
      }

      // element() {
      //  this.setState({
      //   var: true,  
      //  });
      // }
      
    render() {

        
        
        return (
            <>

            <main className="main" role="main">
                <div> 
                    <div>
                    <motion.img transition={{ duration: 0.5, delay: 0.2 }} initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} src={logosecondary} alt="secondary logo" className="secondary-logo" />
                    </div>
                    <motion.h1 transition={{ duration: 0.5, delay: 0.4 }} initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0 }} >Tea finance.<br></br> The <span>bridge</span> between defi and crypto</motion.h1>
                        <hr />
                        <a href="https://www.dextools.io/app/ether/pair-explorer/0x2b4f24e30f9e96789e68500f7ade6698add1e5af">
                        <motion.button whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }} className="viewchart-container">
                            <p>view chart</p>
                        </motion.button>
                        </a>
                </div>
            </main>

            <div className="hud-section">
                <div>
                    <div className="hud">
                        <motion.img transition={{ duration: 0.5, delay: 0.4 }} initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }} src={Cup} alt="secondary logo" className="cup" />
                        <motion.p transition={{ duration: 0.5, delay: 0.5 }} initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }}>Fair Launch</motion.p>
                    </div>
                    <div className="hud">
                        <motion.img transition={{ duration: 0.5, delay: 0.4 }} initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }} src={Cup} alt="secondary logo" className="cup" />
                        <motion.p transition={{ duration: 0.5, delay: 0.5 }} initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }}>DEFI Wallet & Debit Card</motion.p>
                    </div>
                    <div className="hud">
                        <motion.img transition={{ duration: 0.5, delay: 0.4 }} initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }} src={Cup} alt="secondary logo" className="cup" />         
                        <motion.p transition={{ duration: 0.5, delay: 0.5 }} initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }}>Locked liquidity</motion.p>
                    </div>
                    <div className="hud">
                        <motion.img transition={{ duration: 0.5, delay: 0.4 }} initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }} src={Cup} alt="secondary logo" className="cup" />
                        <motion.p transition={{ duration: 0.5, delay: 0.5 }} initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }}>Secure contract</motion.p>
                    </div>
                    
                </div>
            </div>
            
            </>
        );
    }
}

export default Header2;
