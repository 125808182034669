import React, { Component } from "react";
import {Link} from 'react-scroll'
import { motion } from "framer-motion"

class Navdesktop extends Component {
    constructor() {
        super();
        // this.state = {var: null};
        // this.element = this.element.bind(this);
      }

      // element() {
      //  this.setState({
      //   var: true,  
      //  });
      // }

    render() {
        return (
            <nav role="navigation-desktop">
                <ul>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><Link activeClass="active" to="tokenomics" spy={true} smooth={true}>What's in our tea?</Link></motion.li>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><Link activeClass="active" to="roadmap" spy={true} smooth={true}>road map</Link></motion.li>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><Link activeClass="active" to="howtobuy" spy={true} smooth={true}>How to buy</Link></motion.li>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><Link activeClass="active" to="faq" spy={true} smooth={true}>faq</Link></motion.li>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><a href="https://etherscan.io/token/0x7f0cf257fdf5f39db81b72bb1ed7b6fceff60862">contract</a></motion.li>
                </ul>
            </nav>
        );
    }
}

export default Navdesktop;
