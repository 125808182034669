import React, { Component } from "react";
import headerlogo from '../images/logo.png'
import { motion } from "framer-motion"
import {Link} from 'react-scroll'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faTimes
} from "@fortawesome/free-solid-svg-icons";

class Navmobile extends Component {
    constructor() {
        super();
         this.state = {isNavOpen: false};
         this.handleNavClose = this.handleNavClose.bind(this);
      }

      handleNavClose() {
        this.setState({
          isNavOpen: false,
        });
      }

    render() {


        return (
            <>
            <nav className="nav-mobile" role="navigation-mobile">
                <ul>
                    <li onClick={() => this.setState({ isNavOpen: true })}>
                        <a href="#">
                            <FontAwesomeIcon className="fa-bars" icon={faBars} />
                        </a>
                    </li>
                </ul>
            </nav>

            <div className={`Nav ${this.state.isNavOpen ? 'Nav--open' : ''}`}>
                <header className="nav-header">
                     <a href="/"><img src={headerlogo} alt="nav logo" className="nav-logo" /></a>
                     <FontAwesomeIcon  onClick={() => this.setState({ isNavOpen: false })} className="fa-times" icon={faTimes} />
                </header>
                <div className="mobile-nav">
                <ul>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><Link onClick={() => this.setState({ isNavOpen: false })} activeClass="active" to="tokenomics" spy={true} smooth={true}>What's in our tea?</Link></motion.li>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><Link onClick={() => this.setState({ isNavOpen: false })} activeClass="active" to="roadmap" spy={true} smooth={true}>road map</Link></motion.li>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><Link onClick={() => this.setState({ isNavOpen: false })} activeClass="active" to="howtobuy" spy={true} smooth={true}>How to buy</Link></motion.li>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><Link onClick={() => this.setState({ isNavOpen: false })} activeClass="active" to="faq" spy={true} smooth={true}>faq</Link></motion.li>
                    <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><a onClick={() => this.setState({ isNavOpen: false })} href="https://etherscan.io/token/0x7f0cf257fdf5f39db81b72bb1ed7b6fceff60862">contract</a></motion.li>
                    <a href="https://app.uniswap.org/#/swap?inputCurrency=0x7f0cf257fdf5f39db81b72bb1ed7b6fceff60862">
                        <motion.button
                        whileTap={{ scale: 0.9 }} className="nav-buynow-container">
                            <p>buy now</p>
                        </motion.button>
                    </a>
                </ul>
                </div>
            </div>

            <div onClick={() => this.setState({ isNavOpen: false })} className={`Nav2 ${this.state.isNavOpen ? 'Nav--open' : ''}`}></div>
            </>
        );
    }
}

export default Navmobile;
