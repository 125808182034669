import React, { Component } from "react";
import { InView } from 'react-intersection-observer';
import faqlogo from '../images/faq-logo.png'
import { motion } from "framer-motion"
import {Link} from 'react-scroll'


import Faq from 'react-faq-component';

const data = {

    rows: [
        {
            title: "How can I buy $TEAFI token?",
            content: `<p>You can purchase $TEAFI by following our step by step guide above, or you can use our smart contract address to buy on Bittrex exchange: 0x7f0cf257fdf5f39db81b72bb1ed7b6fceff60862</p>`,
        },
        {
            title: "Why am I getting an error message while buying or selling?",
            content: `<p>During times of high trade volume, prices can fluctuate wildly, and that may lead to an error during your trade process. To account for this, try increasing the slippage.</p>`,
        },
        {
            title: "Will there be a presale?",
            content: `<p>We are post-sale here at TEA finance! $TEAFI Coin was launched to our community, and there was no presale before the launch (so don’t worry, you didn’t miss it).</p>`,
        },
        {
            title: "What are the transaction fees?",
            content: `<p>We have broken them down for you here:</p>
           
            <ul>
                <li>● 2% Tax back Uniswap</li>
                <li>● 2.5% Tax back to TEAFI holders</li>
                <li>● 2% Marketing fund</li>
                <li>● 2.5% Reserved for TEAFI APP / debit card holders fund</li>
            </ul>
            `,
        },
        {
            title: "Where are you listed?",
            content: `<p>We are currently listed on <a href="https://app.uniswap.org/#/swap">Uniswap</a></p>`,
        },
        {
            title: "How much do I need to invest?",
            content: `<p>TEAFI is accessible to anyone who wants to purchase. There is no minimum amount required to
            invest. Two percent of every transaction is redistributed to existing holders’ wallets, providing
            investors with additional tokens as they hold</p>.
            `,
        },
    ],
};

const styles = {
        bgColor: "transaprent",
       
};


class Faqapp extends Component {
    constructor() {
        super();
        // this.state = {var: null};
        // this.element = this.element.bind(this);
      }

      // element() {
      //  this.setState({
      //   var: true,  
      //  });
      // }

    render() {
        return (
            <>
            <div className="faq">
                <div>
                <div className="faq-header">
                <InView threshold={1} triggerOnce={true} >
                {({ ref, ref2, inView }) => (
                    <>
                    <motion.h2 ref={ref} transition={{ duration: 0.5, delay: 0.2 }}  animate={inView ? { opacity: 1, y: 0 } : {opacity: 0, y: 20}}>Frequesntly</motion.h2>
                    <motion.h1 ref={ref2} transition={{ duration: 0.5, delay: 0.2 }}  animate={inView ? { opacity: 1, y: 0 } : {opacity: 0, y: 20}}>Asked questions</motion.h1>
                    </>
                )}
                </InView>
                    <hr></hr>
                </div>
                
                <div className="faq-grid">
                    <div className="faq-img">
                        <img src={faqlogo} alt="question mark" />
                    </div>
                    <div>
                        <Faq data={data} styles={styles} />
                    </div>
                </div>

                </div>
            </div>
            </>
        );
    }
}

export default Faqapp;
