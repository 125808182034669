import React, { Component } from "react";
import cardmockup from "../images/cardmockup.png";

class Footer extends Component {
  constructor() {
    super();
    // this.state = {var: null};
    // this.element = this.element.bind(this);
  }

  // element() {
  //  this.setState({
  //   var: true,
  //  });
  // }

  render() {
    return (
      <>
        <footer className="footer" role="footer">
          <div>
            <div className="disclaimer">
              <p>
                <span>LEGAL DISCLAIMER:</span> None of the information on this
                website should be construed as providing legal or financial
                advice. Please note there are always risks associated with smart
                contracts. Please use at your own risk. TEAFI Token is not a
                registered broker, analyst or investment advisor. If you are
                willing to, or have purchased TEAFI, you agree that you are not
                purchasing a security or investment. The TEAFI team can not be
                held liable for any losses or taxes you may incur. You also
                agree that the team is presenting the token as it was launched,
                due to it being renounced it can not be changed. Do conduct your
                own due diligence and consult your financial advisor before
                making any investment decisions.
              </p>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
