import React, { Component, useRef } from "react";
import { useEffect, useState } from "react";
import { motion } from "framer-motion"
import headerlogo from '../images/logo.png'
import logosecondary from '../images/logo-secondary.png'
import Navdesktop from './nav-desktop'
import Navmobile from './nav-mobile'
import Marquee from "react-fast-marquee";
import callAPI from './utils'
const Web3 = require("web3")


const Header = () => {

    const web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/c89f801eef6146ba83703b9e497bdd25"))

    const [latestPrice, setLatestPrice] = useState(0);
  
      useEffect(() => {
          fetchData().then((chartData) => {
              web3.eth.getBalance("0xc25A0a8a327c7FAEfAcf558BdB0a8C378aE85bEa", function(err, result) {
                  if (err) {
                    console.log(err)
                  } else {
                    setLatestPrice(parseFloat(chartData.price[chartData.price.length - 1] * web3.utils.fromWei(result, "ether")).toFixed(2));
              
                  }
                })
              
              
          });
      }, []);
      
      const fetchData = async () => {
          let data = { index: [], price: [], volumes: [] };
          let result = await callAPI("https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=1&interval=1m");
          for (const item of result.prices) {
              data.index.push(item[0]);
              data.price.push(item[1]);
          }
          for (const item of result.total_volumes) data.volumes.push(item[1]);
          return data;
        };



        return (
            <>
            <header className="header" role="header">
                <Marquee gradient={false}  className="Marquee">
                    <p>TEAFI MARKETING WALLET VALUE  <span className="whitespace">-</span> <span style={{color: "#1A1627"}}>${latestPrice} usd</span> <span className="whitespace">-</span></p>
                    <p>TEAFI MARKETING WALLET VALUE  <span className="whitespace">-</span> <span style={{color: "#1A1627"}}>${latestPrice} usd</span> <span className="whitespace">-</span></p>
                    <p>TEAFI MARKETING WALLET VALUE  <span className="whitespace">-</span> <span style={{color: "#1A1627"}}>${latestPrice} usd</span> <span className="whitespace">-</span></p>
                    <p>TEAFI MARKETING WALLET VALUE  <span className="whitespace">-</span> <span style={{color: "#1A1627"}}>${latestPrice} usd</span> <span className="whitespace">-</span></p>
                    <p>TEAFI MARKETING WALLET VALUE  <span className="whitespace">-</span> <span style={{color: "#1A1627"}}>${latestPrice} usd</span> <span className="whitespace">-</span></p>
                    <p>TEAFI MARKETING WALLET VALUE  <span className="whitespace">-</span> <span style={{color: "#1A1627"}}>${latestPrice} usd</span> <span className="whitespace">-</span></p>
                </Marquee>
                <div className="header-wrapper">
                <div className="header-div-1">
                  <a href="/"><img src={headerlogo} alt="header logo" className="header-logo" /></a>
                </div>
                <div className="header-div-2">
                    <Navdesktop />
                </div>
                <div className="header-div-3">
                    <Navmobile />
                    <a href="https://app.uniswap.org/#/swap?inputCurrency=0x7f0cf257fdf5f39db81b72bb1ed7b6fceff60862">
                        <motion.button whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }} className="buynow-container">
                            <p>buy now</p>
                        </motion.button>
                    </a>
                </div>
                </div>
            </header>

            
            
            </>
        );
    }


export default Header;
